import React from "react";
import "./App.css";
import { Route, Redirect, withRouter, Link, Switch } from "react-router-dom";
import PendingStories from "./components/PendingStories";
import Admin from "./components/Admin";
import ApprovedStories from "./components/ApprovedStories";
import AddStories from "./components/AddStories";
import Stories from "./components/Stories";
import ReadAStory from "./components/ReadAStory";

function App() {
  return (
    <div className="App">
      <main className="container">
        <nav>
          <Link to="/">Home</Link>
          <Link to="/newstory">Add a story</Link>
          <Link to="/admin">Admin</Link>
        </nav>
        <Switch>
          
          <Route exact path={"/"} component={Stories} />

          <Route exact path={"/stories/:id"} component={Stories} />
        
          <Route  path="/newstory" component={AddStories} />

          <Route exact path={"/admin"} render={(props)=>{
            return <Admin {...props}/>
          }
           }
            />

          <PrivateRoute
            exact
            path="/admin/pendingStories"
            component={PendingStories}
          />
          <PrivateRoute
            exact
            path="/admin/approvedStories"
            component={ApprovedStories}
          />
        
        </Switch>
      </main>
    </div>
  );
}
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect to="/admin" />
      )
    }
  />
);

export default withRouter(App);
