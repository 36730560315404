import React from 'react';
import { Route, Link } from "react-router-dom";
import ReadAStory from "./ReadAStory";

function Story(props){
    const {story} = props;
    const {match} = props;
    console.log(match)
    
   const  splitStory=(str)=>{
        const arr = str.split(' ');
        const words =  arr.slice(0,35);
        const newStory = words.join(' ');
        return newStory;
     }
     
    return(

        <div className='story'>

            <div className="card-body">
                <h5 className="card-title">{story.title}</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {story.highlight}
                </h6>
            <p className="card-text">{splitStory(story.story)}...</p>
            
                
                <Link to={`/stories/${story.id}`} >READ MORE</Link>
        
              </div>
              
            

             
        </div>
    )
}
export default Story;