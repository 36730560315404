import React, { Component } from "react";
import Joi from "joi-browser";
import axios from "axios";

class AddStories extends Component {
  state = {
    stories: {
      title: "",
      story: ""
    }
  };

  schema = {
    title: Joi.string()
      .required()
      .label("Title"),
    story: Joi.string()
      .required()
      .label("Story")
  };

  doSubmit = () => {

    this.props.history.push("/");
    
  };

  handleChange = ({ currentTarget: input }) => {
    const stories = { ...this.state.stories };
    stories[input.name] = input.value;
    console.log("I was clicked");
    this.setState({
      stories
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    // Call the server
    console.log(`Submitted`, this.state.stories);
    axios
      .post(
        `https://refuge-stories.herokuapp.com/api/stories`,
        this.state.stories
      )
      .then(res => {
      //   this.setState( ...this.state.stories, [{
      //       title: "",
      //       story: ""
      //     }]
      //   )
      debugger
      }
      )
      
      
      .catch(res => {
        debugger;
      });
  };
  
  render() {
    const { stories } = this.state;
    return (
      <div>
        <h1>WHAT'S YOUR STORY?</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">TITLE</label>
            <input
              value={stories.title}
              type="text"
              name="title"
              className="form-control"
              id="title"
              onChange={this.handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">STORY</label>
            <input
              value={stories.story}
              type="text"
              className="form-control"
              id="story"
              onChange={this.handleChange}
              name="story"
            />
          </div>
          <button type="submit" className="btn btn-primary">
            SUBMIT STORY
          </button>
        </form>
      </div>
    );
  }
}

export default AddStories;
