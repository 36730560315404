import React, { useRef } from 'react';
import axios from 'axios';

function LoginPage({props}){
    const emailRef = useRef();
    const passwordRef = useRef();

    const handleLogin = (event)=>{
        event.preventDefault();

        axios.post('https://refuge-stories.herokuapp.com/api/auth/login', {
            email: emailRef.current.value ,
            password: passwordRef.current.value
        })
        .then(res =>{
      
            localStorage.setItem('token', res.data.token);
            props.history.push('/admin/pendingStories')
          
        })
        .catch(er=>{
            alert(er.response.data.message)
        })
    }
    return(
        <div className='loginDiv'>
            <h1 className='loginHeading'>Login</h1>
            <form>
                <label> <span className='emailSpacing'>Email:</span>
                    <input ref={emailRef} name='email' type='text' placeholder='...enter email'/>
                </label>
                <br/>
                <label><span className='passwordSpacing'>Password:</span>
                    <input ref={passwordRef} id='inputSpacing' type='text' name='password' placeholder='...enter password' />
                </label>
                <br/>
                <button onClick={handleLogin}>Login</button>
               
            </form>
        </div>
    )
}
export default LoginPage;