import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import axios from '../axios';

function PendingStories(props){
    
    const [pendingStories, setPendingStories] = useState([]);
   
    
    useEffect(()=>{
        axios().get('https://refuge-stories.herokuapp.com/api/admin/stories')
        .then(res=>{
           
            setPendingStories(res.data);
        })
        .catch(er=>{
            debugger
            alert(er.response.data.message)
        })
    }, [pendingStories])
   
    const approveStory = (id)=>{
        const pendingStory = pendingStories.filter(story=>{
             return story.id === parseInt(id)
        } );
      
        axios().post(`https://refuge-stories.herokuapp.com/api/admin/stories/approve/${id}`,{
       
            "title": pendingStory[0].title,
            "story": pendingStory[0].story,
            "source": pendingStory[0].null
        })
        .then(res=>{
            console.log(res.data.statusText)
        })
        .catch(er=>{
            alert(er.response.data.message)
        })
    } 
    const rejectStory = (id)=>{
        
        axios().delete(`https://refuge-stories.herokuapp.com/api/admin/stories/reject/${id}`)
        .then(res=>{
            console.log(res.data.statusText)
        })
        .catch(er=>{
            alert(er.response.data.message)
        })
    } 
    const logout = ()=>{
        localStorage.removeItem('token');
        props.history.push('/')
    }
    if(!pendingStories){
        return<h1 className='h1Login' >...Loading</h1>
    }
    return(
        <div>
            <nav className='navi'>
            <Link id='link' to='/admin/pendingStories'>Pending Stories</Link>
            <Link id='link' to='/admin/approvedStories'>Approved Stories</Link>
            <Link onClick={logout} id='link' to='/'>Logout</Link>
            </nav>
            <h1 className='h1PendingStories'>Pending Stories</h1>
        
        <div className='pendingStoriesDiv'>
            
             {pendingStories.map(pendingStory=>{
                 return <div className='pendingStoryDiv' key={pendingStory.id}>
                <h2>{pendingStory.title}</h2>
                <p>{pendingStory.story}</p>
                <button className='pendingStoriesButton' onClick={()=>approveStory(`${pendingStory.id}`)}>Approve</button>
                <span className='buttonSpacing'> 
                <button className='pendingStoriesButton'onClick={()=>rejectStory(`${pendingStory.id}`)} >Reject</button>
                </span>
                 </div>
                 
             })

             }   
        </div>
        </div>
    )
}
export default PendingStories;