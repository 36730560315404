import React, { Component } from "react";
import axios from "axios";
import { Route, Link, Switch } from "react-router-dom";
import "../App.css";
import ReadAStory from "./ReadAStory";
import Story from "./Story";
import ReadStories from "./ReadStories";

export class Stories extends Component {
  state = {
    stories: []
  };
  componentDidMount() {
    console.log(this.props);

    axios
    
      .get("https://refuge-stories.herokuapp.com/api/stories")
      .then(response => {
        console.log(response.data);
        let stories = response.data;
        this.setState({
          stories: stories
        });
      })
      .catch(() => {
        console.log("This is an error");
      });
  }
  

  handleDelete = id => {
    // Make a delete request using axios
    console.log("Button clicked");
    debugger;
    axios
      .delete(`https://refuge-stories.herokuapp.com/admin/stories/delete/${id}`)
      .then(response => {
        console.log(response);
        debugger;

        this.setState({});
      })
      .catch(error => {
        debugger;
        // If something goes wrong, we handle any errors here
        console.log(error);
      });
  };
  
 match = this.props;
  render() {
    
    return (
      <React.Fragment>
        <Switch>
        <Route exact path='/' render= {props=>{
          return <ReadStories {...props} stories={this.state.stories}  />
        }}
        />
        <Route path='/stories/:id' render= {props=>{
          return <ReadAStory {...props} story={this.state.stories.filter(story=>(story.id === parseInt(this.props.match.params.id)))} />
        }}
        />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Stories;
