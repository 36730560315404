import React from 'react';
import LoginPage from './LoginPage';
import {Link} from 'react-router-dom';



function Admin(props){
    
    return(
        <div>
            <nav className='navi'>
            <Link id='link' to='/admin/pendingStories'>Pending Stories</Link>
            <Link id='link' to='/admin/approvedStories'>Approved Stories</Link>
            </nav>
            <LoginPage props= {props}/>
        </div>
    )
}
export default Admin;