import React from 'react';
import Story from './Story'

function ReadStories (props){
    const {stories} = props;
    const handleAdd = () => {
      props.history.push("/newstory");
    };
    return(
        <div className='stories'>
            <h1>STORIES</h1>
        <div className="stories-container">
          {stories.map(story => (
            <div
              key={story.id}
              className="card"
              stylestory={{ "minWidth": "70px" }}
            >
              <Story story = {story} {...props}/>
            </div>
          ))}

          <button
            type="button"
            className="btn btn-success"
            onClick={() => handleAdd()}
          >
            ADD STORY
          </button>
        </div>
        </div>
    )
}
export default ReadStories;