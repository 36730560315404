import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Axios from '../axios';
function ApprovedStories(props){
    const [approvedStories, setApprovedStories]= useState([])
    useEffect(()=>{
        axios.get('https://refuge-stories.herokuapp.com/api/stories')
        .then(res=>{
           setApprovedStories(res.data)
        }, [approvedStories])
        .catch(er=>{
            alert('er');
        }, [])
    })
    const deleteStory = (id)=>{
        
        Axios().delete(`https://refuge-stories.herokuapp.com/api/admin/stories/delete/${id}`)
        .then(res=>{
            console.log(res.data.statusText)
        })
        .catch(er=>{
            alert(er.response.data.message)
        })
    } 
    const logout = ()=>{
        localStorage.removeItem('token');
        props.history.push('/')
    }
    if(!approvedStories){
        return <h1 className='h1Login'>...Loading</h1>
    }
    return(
        <div className='approvedStoriesDiv'>
            <nav className='navi'>
            <Link id='link' to='/admin/pendingStories'>Pending Stories</Link>
            <Link id='link' to='/admin/approvedStories'>Approved Stories</Link>
            <Link onClick={logout} id='link' to='/'>Logout</Link>
            </nav>
            <h1 className='h1ApprovedStories'>Approved Stories</h1>
            {
                approvedStories.map((approvedStory)=>{
                return <div className='approvedStoryDiv' key={approvedStory.id}>
                <h2>{approvedStory.title}</h2>
                <p>{approvedStory.story}</p>
                <button onClick={()=>deleteStory(`${approvedStory.id}`)}>Delete</button>
                </div>
                })
            }
            
        </div>
    )
}
export default ApprovedStories;