import React, { useRef, useState }  from 'react';
import { Link } from 'react-router-dom';
import uuid from 'uuid';

function ReadAStory(props){
   
    const [newComment, setNewComment] = useState({
        name: '',
        comment: ''
    })
    const [commentList, setCommentList] = useState([
        { name: "Babatunde",
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    id: uuid()},
        { name: "Kolade",
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        id: uuid()}
    ]);
  const {story} = props;
  const storyObj = {...story[0]};
    const {match} = props
    const onValueChange = (e)=>{
    setNewComment({...newComment, [e.target.name]: e.target.value})
    }
    
    const handleSubmit = (e)=>{
        e.preventDefault()
        
        setCommentList([...commentList,{ name: newComment.name, comment: newComment.comment, id: uuid()}])
        setNewComment({name: '',
        comment: ''})
        
    }
    if(!props.story){
        return <h1>...Loading</h1>
    }
    return(
       
            <div className="card-body">
                {console.log(commentList)}
                <h5 className="card-title">{storyObj.title}</h5>
               
                <p className="card-text">{storyObj.story}</p>
                <div>
                <h4 className='commentHeading'>Comment</h4>
                <h5 className='commentHeading'>Add a comment</h5>
                <form>
                <label> <span className='nameSpacing'>Name:</span>
                    <input value={newComment.name} name='name' type='text' placeholder='...enter name' onChange={onValueChange}/>
                </label>
                <br/>
                <label><span className='commentSpacing'>Comment:</span>
                    <input value={newComment.comment} id='inputSpacing' type='text' name='comment' placeholder='...enter coomment'  onChange={onValueChange}/>
                </label>
                <br/>
                <button onClick={handleSubmit}>Add comment</button>
               
            </form>
                </div>
                {commentList.map(comment=>{
                    return(<div key={comment.id}><p>{comment.name}:   {comment.comment} </p> </div>)
                })}
              </div>
  
    )
}
export default ReadAStory;